import {
  Inject,
  Injectable,
  LOCALE_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from './config.service';
import { ILocaleDTO } from '../dto/config/locale.dto';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private static readonly X_DEFAULT_LOCALE = 'x-default';
  private readonly _renderer2: Renderer2;

  public constructor(
    _rendererFactory2: RendererFactory2,
    private readonly _configService: ConfigService,
    @Inject(LOCALE_ID) private readonly _localeId: string,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this._renderer2 = _rendererFactory2.createRenderer(null, null);
  }

  public setCanonical(path: string): void {
    const localeDTO: ILocaleDTO = this._configService.availableLocales.find(
      (localDTO: ILocaleDTO) => this._localeId === localDTO.value
    )!;
    const href = this._configService.host + localeDTO.baseHref + path;
    const head: HTMLHeadElement = this._document.head;
    let link: HTMLLinkElement | null = head.querySelector(
      `link[rel='canonical']`
    );
    if (!link) {
      link = this._renderer2.createElement('link');
      this._renderer2.appendChild(head, link);
    }
    this._renderer2.setAttribute(link, 'rel', 'canonical');
    this._renderer2.setAttribute(link, 'href', href);
  }

  public setAlternatives(path: string): void {
    const host: string = this._configService.host;
    const head: HTMLHeadElement = this._document.head;
    const defaultLocaleDTO: ILocaleDTO = this._configService.defaultLocale;
    this.setAlternative(
      LinkService.X_DEFAULT_LOCALE,
      host + defaultLocaleDTO.baseHref + path,
      head
    );
    for (const localeDTO of this._configService.availableLocales) {
      this.setAlternative(
        localeDTO.value,
        host + localeDTO.baseHref + path,
        head
      );
    }
  }

  private setAlternative(
    locale: string,
    href: string,
    head: HTMLHeadElement
  ): void {
    let link: HTMLLinkElement | null = head.querySelector(
      `link[rel='alternate'][hreflang='${locale}']`
    );
    if (!link) {
      link = this._renderer2.createElement('link');
      this._renderer2.appendChild(head, link);
    }
    this._renderer2.setAttribute(link, 'rel', 'alternate');
    this._renderer2.setAttribute(link, 'hreflang', locale);
    this._renderer2.setAttribute(link, 'href', href);
  }
}
